<template>
  <section class="contents">
    <div class="mypage" v-if="postData">
      <div class="t-head5">현재 순위: {{ postData.rank }} , 총 득표수: {{ postData.vote_amount }}</div>
      <div class="t-body3 mt10">이 영상이 마음에 드셨다면 투표 혹은 공유해주세요!</div>
      <div class="mt10">
        <VoteButton class="vote" :id="id" @voted="onVoted" />
        <ShareButton class="share ml20" :id="id" />
      </div>
      <a-button @click.stop="gotoNomineesPage" style="padding: 10px 20px; margin-top: 50px; height: 50px">
        <a-icon type="youtube" />
        다른 출품작 보기
      </a-button>
      <h2 class="mt30">제출영상</h2>
      <p style="max-width: 500px; margin: auto">{{ postData.video_info.title }}</p>
      <Media
        v-if="show"
        class="media mt20"
        :type="postData.video_info.platform"
        :link="postData.video_info.embedded_url"
      />
      <div v-if="isEtcType" class="etc-type">
        <div class="thumbnail-wrapper" :class="postData.video_info.platform" @click.stop="gotoLinkPage">
          <div class="thumbnail-layer">
            <img class="thumbnail" :class="postData.video_info.platform" :src="postData.video_info.thumbnail_url" />
            <div class="overlay"></div>
            <div class="play-button"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as API from '@/utils/api'
import Media from '@/components/Media.vue'
import VoteButton from '@/components/VoteButton.vue'
import ShareButton from '@/components/ShareButton.vue'
import TiktokDummy from '@/dummy/tiktok.js'
export default {
  components: {
    Media,
    VoteButton,
    ShareButton,
  },
  data() {
    return {
      link: TiktokDummy[0].html,
      isVoting: false,
      id: this.$route.query.id,
      interval: null,
      postData: null,
      show: false,
    }
  },
  computed: {
    isEtcType() {
      return this.postData?.video_info.platform === 'etc'
    },
  },
  created() {
    this.fetch()
    this.interval = setInterval(() => this.fetch(true), 60000)
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval)
  },
  methods: {
    async fetch(withoutAnimation = false) {
      withoutAnimation || this.$showLoading()
      try {
        this.postData = await API.getPost(this.id)
        if (!this.isEtcType) {
          this.showMedia()
        }
      } catch (err) {
        this.$showErrorMessage(err)
      }
      withoutAnimation || this.$hideLoading()
    },
    showMedia(type) {
      if (type === 'youtube') {
        this.show = true
      } else {
        this.loadScript('https://www.tiktok.com/embed.js').then(status => {
          if (status === 'loaded') {
            this.show = true
          }
        })
      }
    },
    loadScript(url) {
      return new Promise((resolve, reject) => {
        if (document.getElementById('tiktok-script')) {
          resolve('loaded')
        }
        const script = document.createElement('script')
        script.async = true
        script.src = url
        script.setAttribute('id', 'tiktok-script')

        script.onload = () => {
          resolve('loaded')
        }

        script.onerror = () => {
          reject('error')
        }

        document.head.appendChild(script)
      })
    },
    onVoted(count) {
      this.count = count
    },
    gotoNomineesPage() {
      this.$router.push({ name: 'Home' })
    },
    gotoLinkPage() {
      window.open(this.postData.content_url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.mypage {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
  padding: 10px 15px 200px;
  @include tablet {
    padding-top: 20px;
  }

  .etc-type {
    position: relative;
    margin: 20px auto;
    cursor: pointer;
    img {
      max-width: 768px;
    }
    .thumbnail-wrapper {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      cursor: pointer;
      overflow: hidden;
    }
  }
}
</style>
