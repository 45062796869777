export default [
  {
    version: '1.0',
    type: 'video',
    title: 'major tude',
    author_url: 'https://www.tiktok.com/@peachesnbean',
    author_name: 'peaches',
    width: '100%',
    height: '100%',
    html: '<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@peachesnbean/video/7022237493500153093" data-video-id="7022237493500153093" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@peachesnbean" href="https://www.tiktok.com/@peachesnbean">@peachesnbean</a> <p>major tude</p> <a target="_blank" title="♬ original sound - peaches" href="https://www.tiktok.com/music/original-sound-7022237347546696454">♬ original sound - peaches</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script>',
    thumbnail_width: 576,
    thumbnail_height: 1024,
    thumbnail_url:
      'https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/0c3e29327e3f493492034a3b931e7e7c_1634992078?x-expires=1638169200&x-signature=6LFEDs6mIFmc9fF4mAkl8tNMRww%3D',
    provider_url: 'https://www.tiktok.com',
    provider_name: 'TikTok',
    id: '7022237493500153093',
  },
]
